import React, { Component } from "react";

class Navbar extends Component {
  state = {
    menuToggle: false
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      menuToggle: !prevState.menuToggle
    }));
  };

  render() {
    return (
      <div>
        <nav id="navbar" className="">
          <div className="nav-wrapper">
            <div className="logo">
              <a href="/"><i className="fas fa-chess-knight"></i> 
              <img src="../../logoo3.svg" alt="logo" style={{ width: '70px', marginTop: '-5px' }} /></a>
            </div>
            <ul id="menu">
              <li><a href="#home">Home</a></li>
              <li><a href="#service">Service</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#portfolio">References</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
        </nav>

        <div className={`menuIcon ${this.state.menuToggle ? 'toggle' : ''}`} onClick={this.toggleMenu}>
          <span className="icon icon-bars"></span>
          <span className="icon icon-bars overlay"></span>
        </div>

        <div className={`overlay-menu ${this.state.menuToggle ? 'active' : ''}`}>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#service">Service</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#portfolio">References</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Navbar;
