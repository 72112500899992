import React from 'react';
import '../../assets/css/AboutMe.css';
import imageUrl from '../../assets/images/dob3.jpeg'; 

import imageGmail from '../../assets/images/icons/envelope.svg';
import imageLinkedin from '../../assets/images/icons/linkedin.svg'; 
import imageGit from '../../assets/images/icons/github.svg';  

const Contact = () => {
  return (
    <div className="container-fluid lora-section-contact" id='contact'>
      <div className="row">
        <div className="col-md-6 image-section-contact">
          <img src={imageUrl} alt="img" className="img-fluid" />
        </div>
        <div className="col-md-6 text-section-contact">
            <h1 className='lora-title'>Contact me</h1>
            <p className='lora-description'>My goal is that my clients are not only satisfied, but look at their websites with full pride and joy. If you have inspiring ideas or simply want to make your online presence dreams come true, feel free to search!</p>
              <div className="social-am">
                <a href="mailto:kitti970504@gmail.com" target="_blank"  rel="noreferrer">
                <img src={imageGmail} alt="social icon" />
                </a>
                <a href="https://www.linkedin.com/in/nemeth-kitti-050232113/" target="_blank"  rel="noreferrer">
                  <img src={imageLinkedin} alt="social icon" />
                </a>
                <a href="https://github.com/kittii9797" target="_blank"  rel="noreferrer">
                  <img src={imageGit} alt="social icon" />
                </a>
              </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
