// IconsSlider.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const IconsSlider = () => {
    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const icons = [
        { id: 1, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/angular/angular-original.svg", width: "70px"  },
        { id: 2, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bootstrap/bootstrap-original.svg", width: "70px"  },
        { id: 3, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dbeaver/dbeaver-original.svg", width: "60px"  },
        { id: 4, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/docker/docker-original.svg", width: "80px"  },
        { id: 5, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/figma/figma-original.svg", width: "50px"  },
        { id: 6, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original.svg", width: "60px"  },
        { id: 7, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-plain.svg", width: "60px"  },
        { id: 8, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg", width: "60px"  },
        { id: 9, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/laravel/laravel-original.svg", width: "60px"  },
        { id: 10, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg", width: "60px"  },
        { id: 11, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg", width: "70px"  },
        { id: 12, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sass/sass-original.svg", width: "80px"  },
        { id: 13, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/visualstudio/visualstudio-plain.svg", width: "50px"  },
        { id: 14, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vuejs/vuejs-original.svg", width: "60px"  },
        { id: 15, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/wordpress/wordpress-plain.svg", width: "60px"  },
        { id: 16, src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/xd/xd-original.svg", width: "60px" },
        // Add more icons as needed
    ];

    return (
        <div className='slider-body'>
            <Slider {...settings}>
                {icons.map((icon) => (
                    <div key={icon.id}>
                        <img src={icon.src} alt={`Icon ${icon.id}`} style={{ width: `${icon.width}`, height: '100%' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default IconsSlider;
