export const portfolioItems = [
    {
        id: 1,
        imageUrl: '../../rose.jpg',
        title: 'The Million Roses',
        description: 'I maintained the site on a daily basis in Shopify, was responsible for integrating new developments and dealt with SEO optimization. In addition to updating the content of the site, I performed an SEO audit, integrated keywords and improved the performance of the website to increase the number of visitors and the conversion rate.',
        link:"https://themillionroses.com/hu-hu"
    },
    {
        id: 2,
        imageUrl: '../../coffee.png',
        title: 'Seven Mountains Coffee',
        description: 'I built a website based on design in Shopify, I was responsible for the development of the frontend and the integration of the necessary plugins. I also performed basic SEO activities including keyword optimization and setting meta tags to increase page visibility in search engines.',
        link:"https://sevenmountainscoffee.com/"
    },
    {
        id: 3,
        imageUrl: '../../bag.jpg',
        title: 'Your Art Bags',
        description: 'I built a website based on design in Wordpress, I was responsible for its development and the integration of the necessary plugins.',
        link:"https://taska.yourart.hu/"
    },
    {
        id: 4,
        imageUrl: '../../heliaD.jpg',
        title: 'Helia D',
        description: 'During the project, I developed a question-and-answer mini-application, where I displayed the corresponding result based on the answers given by the users. I was responsible for the entire development of the mini application, including the design and implementation of the user interface.',
        link:"https://www.audiarena.hu/"
    },
    {
        id: 5,
        imageUrl: '../../jungle.jpg',
        title: 'Jungle Coworking',
        description: "During the project, I dealt with the design and development of the website. I built the site in the WordPress content management system. I also integrated many necessary plugins such as booking systems, contact forms and other business functions to optimize the site's functionality and user experience.",
        link:"https://junglecoworking.hu/"
    },
    {
        id: 6,
        imageUrl: '../../brigitte.jpg',
        title: 'Brigitte Beauty',
        description: 'During the project, I dealt with the design and development of the website. I built the site in the WordPress content management system. I was also responsible for site maintenance for a while.',
        link:"https://www.brigittebeauty.hu/"
    },
    {
        id: 7,
        imageUrl: '../../86128.jpg',
        title: 'Audi Arena',
        description: 'During the project, I was responsible for the development of the site, working on both the backend and frontend parts. Additionally, I maintained the site on a daily basis and regularly handled SEO optimization. When necessary, I updated the site to meet the latest requirements and customer requests.',
        link:"https://www.helia-d.hu/teszt"
    },
    {
        id: 8,
        imageUrl: '../../osp.jpg',
        title: 'Olimpiai Sportpark',
        description: 'During the project, I was responsible for the development of the site, working on both the backend and frontend parts. Additionally, I maintained the site on a daily basis and regularly handled SEO optimization. When necessary, I updated the site to meet the latest requirements and customer requests.',
        link:"https://ospgyor.hu/"
    },
    {
        id: 9,
        imageUrl: '../../temeto1.jpg',
        title: 'Grave finder Győr',
        description: 'During the project, I was mostly responsible for the development of the frontend. Several of us worked on the project, so I was only responsible for small parts here.',
        link:"https://sirhelykereso.gyorszol.hu/"
    },
  ];