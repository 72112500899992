import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/TestimonialSection.css';

const TestimonialSection = ({ models }) => {
  const [currentIndex, setCurrentIndex] = useState(2); // Kezdjük a harmadik elemmel, hogy az első három látható legyen
  const timeoutRef = useRef(null);
  const delay = 8000;

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === models.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, models.length]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const getInactiveIndexes = () => {
    let indexes = [];
    for (let i = 1; i <= 2; i++) {
      indexes.push((currentIndex + i) % models.length);
    }
    return indexes;
  };

  const inactiveIndexes = getInactiveIndexes();

  return (
    <section className="testimonial-section" id='portfolio'>
      <div className="testi-user-img">
        <div className="gallery-thumbs">
          <div className="inactive-thumbs">
            {inactiveIndexes.map((index) => (
              <div key={index} className="thumb inactive">
                <img src={models[index].imageUrl} alt={models[index].title} />
              </div>
            ))}
          </div>
          <div className="active-thumb">
            <div key={currentIndex} className="thumb active">
              <img src={models[currentIndex].imageUrl} alt={models[currentIndex].title} />
              <a className="user-saying" href={models[currentIndex].link} target="_blank" rel="noreferrer">
                <div className="testimonial">
                  <div className="quote active">
                    <p>{models[currentIndex].title}</p>
                    <div className="designation">{models[currentIndex].description}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
