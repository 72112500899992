import React, {useEffect} from "react";
import {
    BrowserRouter as Router,
  } from "react-router-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Home from "../home/Home";
import AboutMe from "../about/AboutMe";
import Contact from "../contact/Contact";

import { portfolioItems } from '../../models/models'
import Service from "../service/Service";
import TestimonialSection from "../portfolio/Testimonial";

import ScrollReveal from 'scrollreveal';

export const Pages = () => {

  // useStates

  // events handler

  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'top',
      distance: '80px',
      duration: 2000,
      reset: true
    });

    sr.reveal('.text-section', {});
    sr.reveal('.text-section-contact', {});
    sr.reveal('.lora-title', { delay: 200 });
    sr.reveal('.lora-description', { delay: 300 });
    sr.reveal('.social-am', { interval: 400 });
  }, []);

  return  <Router>
              <Navbar />
              <Home />
              <Service/>
              <AboutMe />
              < TestimonialSection models={portfolioItems} />
              <Contact />
              <Footer />
          </Router>
}