import React from 'react';
import '../../assets/css/HomeSection.css';

const HomeSection = () => {
    return (
        <div id="home" className="home-section">
            <div className="content">
                <h1>I'm Kitti, a developer</h1>
                <p>I have several years of experience in the development of websites and software, my goal is to create user-friendly products for people that are not only aesthetic, but also easy to use and efficient. I believe that a well-designed website is key to a successful online presence.</p>
                <a className="btn-home" href="#contact">Contact me</a>
            </div>
        </div>
    );
};

export default HomeSection;