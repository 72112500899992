import React from 'react';
import '../../assets/css/AboutMe.css';
import imageUrl from '../../assets/images/am3.jpg'; 

const AboutMe = () => {
  return (
    <div className="container-fluid lora-section" id='about'>
      <div className="row">
        <div className="col-md-6 text-section">
          <h1 className="lora-title">About me</h1>
          <p className="lora-description">
            I am a young self-employed person who has mastered two professions in four years. 
            I stand my ground in the world as both a graphic designer and a developer. 
            I like to learn new things and I bravely face challenges. 
            Learning is eternal, potential knowledge is limitless. 
            Apart from these, my sports career of more than ten years has taught me many things that I can use.
          </p>
        </div>
        <div className="col-md-6 image-section">
          <img src={imageUrl} alt="img" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default AboutMe;

