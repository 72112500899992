import React from 'react';
import '../../assets/css/Service.css';
import imageUrl from '../../assets/images/Mask group.png'; 
import IconsSlider from '../icons/IconsSlider';

import imageDesign from '../../assets/images/icons/design.svg';
import imageWeb from '../../assets/images/icons/web.svg'; 
import imageCoding from '../../assets/images/icons/coding.svg'; 



const Service = () => {
  return (
    <div className="container-fluid lora-section-service" id='service'>
      <div className="row">
        <div className="col-md-6 image-section-service">
          <img src={imageUrl} alt="img" className="img-fluid" />
        </div>
        <div className="col-md-6 text-section-service">
            <h1 className='lora-title-service'>Service</h1>
            <div className='lora-serv-section'>
                <img src={imageDesign} alt="icon" />
                <p className='lora-description-service'>I design unique and responsive web designs that emphasize the image of your business and ensure the best user experience on all devices.</p>
            </div>
            <div className='lora-serv-section'>
                <img src={imageWeb} alt="icon" />
                <p className='lora-description-service'>I create professional WordPress and Shopify websites quickly and cost-effectively, be it blogs, company pages or online stores.</p>
            </div>
            <div className='lora-serv-section'>
                <img src={imageCoding} alt="icon" />
                <p className='lora-description-service'>We offer modern, dynamic frontend (React, Vue) and reliable, scalable backend (Laravel) development for best performance.</p>
            </div>
            <IconsSlider />
        </div>
      </div>
    </div>
  );
}

export default Service;
